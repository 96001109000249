@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/Jost/Jost-Light.woff2') format('woff2');

}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/static/fonts/Jost/Jost-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/static/fonts/Jost/Jost-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/static/fonts/Jost/Jost-SemiBold.woff2') format('woff2');
}
